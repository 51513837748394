import { sendEmail } from "@/util/api";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      contactArea: ""
    };
  },
  methods: {
    // 发送邮件
    async sendMessage() {
      const res = await sendEmail({
        data: {},
        dataType: "json"
      });
      console.log(res);
      if (res) {
        ElMessage({
          message: "邮件发送成功~",
          type: "succsee"
        });
      }
    }
  }
};