import TwoLinesText from "./TwoLinesText.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    model: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      required: true
    },
    img: {
      type: String,
      default: ""
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      productsBrand: "getProductsBrand"
    })
  },
  components: {
    TwoLinesText
  },
  data() {
    return {
      isEnOrZh: window.localStorage.getItem("language")
    };
  }
};