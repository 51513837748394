module.exports = {
  common: {
    // 导航栏
    header: {
      home: "主页",
      products: {
        products: "产品",
        nyoni: "尼奥尼",
        feiyan: "飞雁",
      },
      about: {
        about: "关于",
        jiuling: "久灵",
        nyoni: "尼奥尼",
        hzmanqini: "曼奇尼",
      },
      contact: "联系",
      buy: "商城",
      language: {
        lg: "语言",
        zh: "中文",
        en: "英文",
      },
      close: "关闭",
      menu: "菜单",
    },
    footer: {
      procudts: {
        h1: "产品",
        nyoni: "尼奥尼",
        feiyan: "飞雁",
      },
      contact: {
        h1: "联系我们",
        landline: "固定电话",
        phone: "移动电话",
        email: "企业邮箱",
      },
      about: {
        h1: "关于我们",
        factory1: "工厂",
        factory2: "浙江久灵笔刷有限公司",
        trade1: "外贸",
        trade2: "杭州曼奇尼文化用品有限公司",
      },
      compinfo: {
        // compinfo: `尼奥尼是中国画材市场的一线品牌，专注于炭笔、彩色铅笔、素描铅笔和其他绘画文具。`,
        compinfo: `NYONI is the leading brand in Chinese drawing market. NYONI specializes in charcoal pencil, color pencil, sketch pencil and many other drawing tools. And our factory also offer the OEM for customer.`,
      },
    },
    productsCategory: {
      color_pencil: "彩色铅笔",
      // color_pencil_intro: "一起走进色彩斑澜的绘画世界",
      sketch_pencil: "素描铅笔",
      charcoal_pencil: "炭笔",
      school_office_pencil: "学校办公铅笔",
      eraser: "橡皮",
      other_tools: "其他文具",
    },
  },
  // 购买链接
  buy: {
    title: "尼奥尼商城链接",
  },
  // 首页
  home: {
    header: {
      title1: "尼奥尼 - 勾勒您艺术的方向",
      title2: `尼奥尼是中国一线画材品牌，专注于专业绘画领域产品研发和生产。`,
    },
    companyIntroduction: {
      title: "尼奥尼 - 勾勒您艺术的方向",
      content: `浙江久灵笔刷有限公司成立于1999年，是一家集产供研于一体的专业生产销售中高档铅笔系列产品的企业，旗下拥有飞雁、少年派、尼奥尼、绔姿等品牌。公司坐落于被誉为“中国生态第一县”之称的丽水市庆元县竹口镇工业园区。竹口镇被称为“中国铅笔小镇”，是中国铅笔生产企业的集聚地，铅笔产量占国内铅笔总量的四分之一强。浙江久笔刷有限公司是庆元县最早研发、生产和销售铅笔的企业之一，产销量始终稳居全县铅笔行业的前列。`,
    },
    productsList: {
      title: "产品类目",
      content: `尼奥尼成立于2015年，以“勾勒您艺术的方向”为理念。至今, 尼奥尼已是中国绘画市场的一线品牌。为了带给世界最优质的画材，我们将坚持不懈，保持匠心精神。
      `,
    },
    enterpriseSpirit: {
      title1: "以客户为中心",
      title2: "比竞争者更好的满足顾客期望",
      content: `全体浙江久灵人将继续弘扬“拼搏、进取、创新、求实”的企业精神，秉承“高标准、精细化、全程监控”的质量要求和“以客户为中心，比竞争者更好的满足顾客期望”的企业理念， 解放思想，振奋精神，齐心协力，埋头苦干，创造久灵更加美好的明天!`,
    },
    button: {
      about: "关于我们",
      product: "查看全部",
      contact: "联系我们",
    },
  },
  products: {
    title: "产品品牌",
    nyoni: "尼奥尼",
    feiyan: "飞雁",
    nyoinTitle: "尼奥尼专注于专业画材研发和生产。",
    feiyanTitle: "飞雁专注于学校办公书写铅笔研发和生产。",
  },
  // 产品详情页
  details: {
    recommendText: "你可能喜欢",
    price: "零售价",
    buyLink: "购买链接",
    productDetails: "产品详情",
    model: "型号",
    type: "类型",
    size: "尺寸",
    material: "材料",
    package: "包装",
  },
  // 联系我们
  contact: {
    title: "联系我们",
    content: "让我们合作共赢",
    fullName: "名字",
    emailAddress: "邮箱",
    company: "公司",
    subject: "主题",
    // subjectPlaceholder: "主题",
    // fullNamePlaceholder: "名字",
    // emailAddressPlaceholder: "邮件地址",
    // companyPlaceholder: "公司",
    message: "邮件内容",
    submit: "提交",
    address: "地址",
    factoryAdd: `中国浙江省丽水市庆元县竹口工业园区1号`,
    tradeAdd: `外贸地址: 中国浙江省杭州市西湖区金街美地5号楼816室`,
    time: "工作时间",
    weekDay: "星期一 - 星期日",
    weekTime: "上午9点 - 下午6点",
    contact: "联系方式",
  },
  // 关于我们
  about: {
    nyoni: {
      title: "尼奥尼 | 专业一线画材品牌",
      content: `尼奥尼品牌于2015年创建,其理念是“勾勒您艺术的方向”。`,
    },
    jiuling: {
      title: "久灵 | 尼奥尼品牌企业",
      content: `浙江久灵笔刷有限公司主营学校办公铅笔、专业绘画文具产品研发生产。`,
      textTitle: "浙江久灵笔刷有限公司",
      textContent: `浙江久灵笔刷有限公司成立于1999年，是一家集产供研于一体的专业生产销售中高档铅笔、化妆笔以及彩妆系列产品的企业，旗下拥有飞雁、少年派、尼奥尼、绔姿等品牌。`,
    },
    hzmanqini: {
      title: "杭州曼奇尼 | 尼奥尼阿里巴巴国际站唯一销售总代理",
      content: `杭州曼奇尼文化用品有限公司是浙江久灵笔刷有限公司在阿里巴巴国际站的唯一销售总代理。`,
      textTitle: "杭州曼奇尼文化用品有限公司",
      textContent: `杭州曼奇尼文化用品有限公司是浙江久灵笔刷有限公司在阿里巴巴国际站的唯一销售总代理, 负责尼奥尼等品牌在国际站上的销售、客户服务等相关业务经营及推广事宜。`,
    },
  },
  jiuling: {
    companyIntroductions: "公司介绍",
    enterpriseHonor: "企业荣誉",
    companyEnvironment: "公司环境",
    companyIntroduction: {
      p1: `浙江久灵笔刷有限公司成立于1999年，是一家集产供研于一体的专业生产销售中高档铅笔、化妆笔以及彩妆系列产品的企业，旗下拥有飞雁、少年派、尼奥尼、绔姿等品牌。`,
      p2: `公司坐落于被誉为“中国生态第一县”之称的丽水市庆元县竹口镇工业园区。竹口镇被称为“中国铅笔小镇”，是中国铅笔生产企业的集聚地，铅笔产量占国内铅笔总量的四分之一强。浙江久笔刷有限公司是庆元县最早研发、生产和销售铅笔的企业之一，产销量始终稳居全县铅笔行业的前列。`,
      p3: `公司占地面积52亩，建筑面积45000平方，拥有专业生产铅笔各类设备五百多台套，铅笔年生产能力3.2亿支。公司现有职工450人，其中各类专业技术人员63人，各级管理人员55人。`,
      p4: `公司起步于铅笔的生产和销售，是中国知名的铅笔制造企业，现为中国制笔协会常务理事单位、庆元县铅笔协会会长单位和国家铅笔行业标准起草单位。董事长沈世正先生是企业法定代表人，多次被评为庆元十佳优秀厂长（经理）、庆元县突出人才贡献者，同时他还是庆元十四、十五届人大代表、庆元县十五届人大常委，2012年被评为全国铅笔委员会委员，同年被中国制笔协会评选为“中国制笔行业突出贡献者”，2013年被浙江省中小企业协会选为“浙江省优秀企业家”。`,
      p5: `公司早在2005年就开始推行系统化、规范化管理，先后通过了ISO9001、ISO22716、GMP、FSC等体系认证。公司先后荣获浙江省著名商标、丽水市著名商标、浙江省“AAA”级守合同重信用单位、丽水市高新技术企业、浙江省科技型企业、庆元县“小巨人培育”企业、转型升级示范企业、爱心企业、工业十强企业等荣誉。`,
      p6: `“飞雁”是浙江久灵旗下最知名品牌，品牌注册于2009年。“飞雁”从诞生的第一天起，公司就将品牌定位为高端制造，立志成为中国铅笔行业的引领者。经过几年时间的潜心钻研和沉淀，“飞雁”品牌已经成为高档铅笔的代名词，品牌影响力在铅笔市场与日俱增。公司生产的飞雁牌绘图铅笔、学生铅笔，在2013年8月被中国制笔协会评选为“中国制笔名牌”；飞雁牌2B铅笔被中国制笔协会特别指定为考试专用铅笔。2014年10月，飞雁牌绘图铅笔在法国罗浮宫举办的中西方艺术展上获得创新设计奖，此次获奖被评为中国制笔行业2015年度10件大事之一。2015年5月，“飞雁”铅笔被全国首届美术大学生写生大赛唯一指定专用铅笔；同年，“飞雁”铅笔被评为浙江省著名商标，是全国三家被评为省级著名商标的铅笔企业之一。`,
      p7: `“尼奥尼”是浙江久灵的又一明星品牌，品牌注册于2014年。如果说飞雁品牌是书写铅笔中的贵族，那么尼奥尼就是画材铅笔中金字塔尖上的那颗明珠。虽然尼奥尼诞生时间不长，但是凭借优异的质量和完善的服务硬是在专业炭笔市场上刮起了龙卷风，短短几年时间，尼奥尼的市场美誉度和占有率直指行业桂冠。`,
      p8: `为了让企业稳健发展，公司于2015年制定了中长期发展战略，即：至2025年实现销售收入10亿元，做中国最有价值的企业，成为在国内行业领先、国际上具有竞争力的铅笔生产企业，飞雁品牌跻身行业三甲，尼奥尼品牌市场占有率第一名；公司将进一步突出低成本、高质量的优势，形成久灵特有的核心竞争力，全面满足顾客、股东和员工的期望。全体浙江久灵人将继续弘扬“拼搏、进取、创新、求实”的企业精神，秉承“高标准、精细化、全程监控”的质量要求和“以客户为中心，比竞争者更好的满足顾客期望”的企业理念， 解放思想，振奋精神，齐心协力，埋头苦干，创造久灵更加美好的明天!`,
    },
  },
};
