import "core-js/modules/es.array.push.js";
import Headernav from "@/components/Home/Header.vue";
import Banner from "@/components/Home/Banner.vue";
import ProductsList from "@/components/Home/ProductsList.vue";
export default {
  name: "Home",
  components: {
    Banner,
    Headernav,
    ProductsList
  },
  methods: {
    toRouter(data) {
      if (data == "products") {
        this.$router.push({
          name: "products",
          params: {
            brand: "nyoni"
          }
        });
      } else {
        this.$router.push({
          name: data
        });
      }
    }
  }
};