import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["title"];
const _hoisted_2 = {
  class: "two-lines-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("a", {
    class: "two-lines-text-link",
    target: "_blank",
    title: $props.name
  }, [_createElementVNode("span", _hoisted_2, _toDisplayString($props.name), 1)], 8, _hoisted_1)]);
}