import { ElCarousel, ElCarouselItem } from "element-plus";
export default {
  components: {
    ElCarousel,
    ElCarouselItem
  },
  props: {
    swiperNum: {
      type: Number,
      default: 3
    },
    ImageNum: {
      type: Number,
      default: 4
    },
    file: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      imageSwiperHeight: "224"
    };
  },
  mounted() {
    setTimeout(() => {
      this.imgLoad();
    }, 500);
    window.addEventListener("resize", () => {
      this.imgLoad();
    }, false);
  },
  methods: {
    imgLoad() {
      this.$nextTick(() => {
        this.imageSwiperHeight = this.$refs.imageSwiperRef[0].offsetHeight;
        console.log(this.$refs.imageSwiperRef[0].offsetHeight);
      });
    }
  }
};