module.exports = {
  // 通用
  common: {
    // 导航栏
    header: {
      home: "Home",
      // products: "Products",
      products: {
        products: "Products",
        nyoni: "NYONI",
        feiyan: "FEIYAN",
      },
      about: {
        about: "About",
        nyoni: "NYONI",
        jiuling: "JIULING",
        hzmanqini: "HZMANQINI",
      },
      contact: "Contact",
      buy: "Shop",
      language: {
        lg: "Language",
        zh: "Chinese",
        en: "English",
      },
      close: "CLOSE",
      menu: "MENU",
    },
    // 页脚
    footer: {
      procudts: {
        h1: "PRODUCTS",
        nyoni: "NYONI",
        feiyan: "FEIYAN",
      },
      contact: {
        h1: "CONTACT",
        landline: "Landline",
        phone: "Phone",
        email: "Email",
      },
      about: {
        h1: "ABOUT US",
        factory1: "Factory",
        factory2: "Zhejiang Jiuling Pencil & Brush Co., Ltd",
        trade1: "Trade",
        trade2: "Hangzhou Manqini Stationery Co., Ltd",
      },
      compinfo: {
        compinfo: `NYONI is the leading brand in Chinese drawing market. NYONI specializes in charcoal pencil, color pencil, sketch pencil and many other drawing tools. And our factory also offer the OEM for customer.`,
      },
    },
    // 产品类目
    productsCategory: {
      color_pencil: "COLOR PENCIL",
      sketch_pencil: "SKETCH PENCIL",
      charcoal_pencil: "CHARCOAL PENCIL",
      school_office_pencil: "SCHOOL & OFFICE PENCIL",
      eraser: "ERASER",
      other_tools: "OTHER TOOLS",
    },
  },
  // 购买链接
  buy: {
    title: "NYONI SHOP LINK",
  },
  // 首页
  home: {
    header: {
      title1: "NYONI - DEFINE YOUR DIRECTION OF ART",
      title2: `NYONI is a top brand of Chinese painting materials, focus on professional drawing products development and production."`,
    },
    companyIntroduction: {
      title: "NYONI - Define Your Direction Of Art",
      content: `Zhejiang jiuling pens & brushes co., ltd. is a professional company for pens making with a history of 20 years. We have our own factory with more than 400 employees.  It is also the leading enterprise of Zhejiang province.  And the annual output value is about 500 million cny. In addition, it engaged in its own brands that are called "NYONI" and "FEIYAN" which have the best sales volume in the Chinese market. nd the "NYONI" mainly makes painting tools, such as charcoal pencils, color pencils and eraser.  The "FEIYAN" mainly makes the office & school pencil.`,
      // content: `Zhejiang Jiuling Pencil & Brush Co., Ltd. was established in 1999, is a collection of production, research and development in one of the professional production and sales of high-grade pencil series of enterprises, owns FEIYAN, SHAONIANPAI, Nyoni, KUAZI and other brands. The company is located in zhukou Town industrial Park, Qingyuan County, Lishui City, known as "The first ecological county in China". Zhukou town, known as "China's pencil town", is a gathering place for Chinese pencil manufacturers, accounting for more than a quarter of the country's total pencil output. Zhejiang Jiu brush Co., Ltd. is one of the earliest enterprises that develop, produce and sell pencils in Qingyuan county.`,
      // 浙江久灵笔刷有限公司成立于1999年，是一家集产供研于一体的专业生产销售中高档铅笔系列产品的企业，旗下拥有飞雁、少年派、尼奥尼、绔姿等品牌。公司坐落于被誉为“中国生态第一县”之称的丽水市庆元县竹口镇工业园区。竹口镇被称为“中国铅笔小镇”，是中国铅笔生产企业的集聚地，铅笔产量占国内铅笔总量的四分之一强。浙江久笔刷有限公司是庆元县最早研发、生产和销售铅笔的企业之一，产销量始终稳居全县铅笔行业的前列。
    },
    productsList: {
      title: "THE PRODUCTS CATEGORY",
      content: `With the concept of "Define Your Direction Of Art", NYONI was founded in 2015. Now NYONI is the leading brand in Chinese drawing market. In order to bring the best quality painting materials to the world, we will persevere and keep the spirit of ingenuity.
`,
    },
    enterpriseSpirit: {
      title1: "CUSTOMER FOCUS",
      title2: "Better Meet Customer Expectations Than Competitors",
      content: `All Zhejiang Jiuling people will continue to carry forward the enterprise spirit of "hard work, enterprising, innovation and realism", adhere to the quality requirements of "high standards, refinement and whole process monitoring" and the enterprise concept of "customer-centric, better meet customer expectations than competitors", emancipate the mind, inspire the spirit, work together, and create a better tomorrow for Jiuling!`,
    },
    button: {
      about: "ABOUT US",
      product: "VIEW ALL",
      contact: "CONTACT US",
    },
  },
  // 产品列表
  products: {
    title: "PRODUCTS BRANDS",
    nyoni: "NYONI",
    feiyan: "FEIYAN",
    nyoinTitle: `NYONI focuses on the development and production of professional painting materials`,
    feiyanTitle: `FEIYAN focuses on the development and production of school & office writing pencils`,
  },
  // 产品详情页
  details: {
    recommendText: "You may also like",
    price: "Retail Price",
    buyLink: "Buy Link",
    productDetails: "Product Details",
    model: "Model",
    type: "Type",
    size: "Size",
    material: "Material",
    package: "Package",
  },
  // 联系我们
  contact: {
    title: "CONTACT US",
    content: "Let's talk about perfect win-win cooperation",
    fullName: "FULL NAME",
    company: "COMPANY",
    emailAddress: "EMAIL ADDRESS",
    subject: "SUBJECT",
    message: "Message",
    submit: "SUBMIT",
    address: "Address",
    factoryAdd: `No.1, Zhukou Industrial, Qingyuan, Lishui, Zhejiang,
    China.`,
    tradeAdd: `Trade Add:Room 816, Block 5, Jinjiemeidi Commercial Center,Xihu
    district, Hangzhou city, Zhejiang, China.`,
    time: "Hours Of Operation",
    weekDay: "Monday-Sunday",
    weekTime: "09am - 06pm",
    contact: "Contact Info",
  },
  // 关于我们
  about: {
    nyoni: {
      title: "NYONI | PROFESSIONAL PAINTING MATERIAL BRAND",
      content: `With the concept of "Define Your Direction Of Art", NYONI was founded in
      2015.`,
    },
    jiuling: {
      title: "JIULING | NYONI FACTORY",
      content: `Zhejiang Jiuling Pencil & Brush Co., Ltd focus on student pencil and professional art tools`,
      textTitle: "Zhejiang Jiuling Pencil & Brush Co., Ltd",
      textContent: `JIULING was founded in 1999, is a collection of production, research and development in one
      of the professional production and sales of high-grade pencils, makeup
      pens and cosmetics series of products enterprises, it owns FEIYAN,
      SHAONIANPAI, NYONI, KUAZI brands.`,
    },
    hzmanqini: {
      title: "HZMANQINI | NYONI SOLE SAlES AGENT IN ALIBABA.COM",
      content: `HangZhou Manqini Stationery Co., Ltd as Zhejiang Jiuling Pencil & Brush Co., Ltd
                sole sales agent in Alibaba.com.`,
      textTitle: "HangZhou Manqini Stationery Co., Ltd",
      textContent: `HangZhou Manqini Stationery Co., Ltd as Zhejiang Jiuling Brush Co.,
                    Ltd sole sales agent in Alibaba.com. Manqini will be responsible for
                    the sales about brand "NYONI" and customer service in Alibaba website.`,
    },
  },
  jiuling: {
    companyIntroductions: "COMPANY INTRODUCTION",
    enterpriseHonor: "ENTERPRISE HONOR",
    companyEnvironment: "COMPANY ENVIRONMENT",
    companyIntroduction: {
      p1: `Zhejiang Jiuling Pencil & Brush Co., Ltd founded in 1999, is an enterprise integrating production, supply and research, specializing in the production and sales of medium and high-end pencils, makeup pens and makeup series products. It owns brands such as FEIYAN, SHAONIANPAI, NYONI, KUAZI and so on.`,
      p2: `The company is located in Zhukou Town Industrial Park, Qingyuan County, Lishui City, which is known as "the first ecological county in China". Zhukou Town, known as the "pencil town of China", is the gathering place of Chinese pencil production enterprises, and the pencil output accounts for more than a quarter of the total domestic pencil output. Zhejiang Jiu brush Co., Ltd. is one of the first enterprises in Qingyuan County to develop, produce and sell pencils, and its production and sales volume has always been in the forefront of the pencil industry in the county.`,
      p3: `The company covers an area of 52 Mu and a construction area of 45000 square meters. It has more than 500 sets of equipment specializing in the production of pencils, with an annual production capacity of 320million pencils. The company has 450 employees, including 63 professional technicians and 55 management personnel at all levels.`,
      p4: `Starting from the production and sales of pencils, the company is a well-known pencil manufacturer in China. It is now the executive director unit of China pencil making Association, the president unit of Qingyuan pencil Association and the drafting unit of national pencil industry standards. Mr. Shen Shizheng, the chairman of the board, is the legal representative of the enterprise. He has been rated as one of the top ten outstanding factory directors (managers) in Qingyuan County and a contributor of outstanding talents in Qingyuan County for many times. At the same time, he is also a representative of the 14th and 15th people's congresses of Qingyuan County and a member of the Standing Committee of the 15th people's Congress of Qingyuan County. In 2012, he was rated as a member of the national pencil Committee and the "outstanding contributor of China's pen industry" by the China pen making Association in the same year, In 2013, he was selected as "outstanding entrepreneur of Zhejiang Province" by Zhejiang small and Medium Enterprises Association.`,
      p5: `The company began to implement systematic and standardized management as early as 2005, and has passed ISO9001, iso22716, GMP, FSC and other system certifications. The company has won the honors of Zhejiang famous trademark, Lishui famous trademark, Zhejiang "AAA" contract abiding and trustworthy unit, Lishui high-tech enterprise, Zhejiang science and technology enterprise, Qingyuan County "small giant cultivation" enterprise, transformation and upgrading demonstration enterprise, love enterprise, top ten industrial enterprises and so on.`,
      p6: `"FEIYAN" is the most famous brand under Zhejiang Jiuling, which was registered in 2009. From the first day of its birth, "FEIYAN" has positioned its brand as high-end manufacturing and is determined to become a leader in China's pencil industry. After years of painstaking research and precipitation, "FEIYAN" brand has become synonymous with high-end pencils, and its brand influence is increasing in the pencil Market. FEIYAN brand drawing pencils and student pencils produced by the company were selected as "Chinese pen brand" by China pen making Association in August 2013; FEIYAN 2B pencil is specially designated as a special pencil for examination by China pen making Association. In October, 2014, FEIYAN drawing pencil won the innovative design award at the Chinese and Western art exhibition held in the Louvre, France. This award was rated as one of the 10 major events in China's pen industry in 2015. In May, 2015, "flying goose" pencil was the only designated pencil for the first national art college students' sketching competition; In the same year, "FEIYAN" pencil was rated as a famous trademark in Zhejiang Province, and it is one of the three pencil enterprises rated as provincial famous trademarks in China.`,
      p7: `"NYONI" is another star brand of Zhejiang Jiuling, which was registered in 2014. If the flying geese brand is the aristocrat in writing pencils, then NYONI is the Pearl on the tip of the pyramid in painting pencils. Although NYONI was not born long ago, it just blew a tornado in the professional charcoal market with excellent quality and perfect service. In just a few years, NYONI's market reputation and share directly point to the industry crown.`,
      p8: `In order to ensure the steady development of the enterprise, the company formulated a medium and long-term development strategy in 2015, that is, to achieve a sales revenue of 1billion yuan by 2025, become the most valuable enterprise in China, become a pencil manufacturer with leading domestic industry and international competitiveness, the flying goose brand ranks among the top three in the industry, and the market share of NYONI brand ranks first; The company will further highlight the advantages of low cost and high quality, form the unique core competitiveness of Jiuling, and fully meet the expectations of customers, shareholders and employees. All Zhejiang Jiuling people will continue to carry forward the enterprise spirit of "hard work, enterprising, innovation and realism", uphold the quality requirements of "high standards, refinement and whole process monitoring" and the enterprise concept of "customer-centric, better meet customer expectations than competitors", emancipate the mind, inspire the spirit, work together, and create a better tomorrow for Jiuling!`,
    },
  },
};
