import "core-js/modules/es.array.push.js";
import ProductItem from "./ProductItem.vue";
import { ElNotification } from "element-plus";
import { ElLoading } from "element-plus";
export default {
  components: {
    ProductItem
  },
  props: {
    products: {
      type: Array,
      required: true
    },
    // 是否可转行
    wrap: {
      type: Boolean,
      default: true
    },
    // 是否监听产品列表高度
    listenHeight: {
      type: Boolean,
      default: false
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    products() {
      this.loading();
    }
  },
  data() {
    return {
      productName: "",
      isEnOrZh: window.localStorage.getItem("language"),
      ElNotificationshow: false
    };
  },
  mounted() {
    this.loading();
    if (this.listenHeight) {
      setTimeout(() => {
        this.emitHeight();
        window.addEventListener("resize", () => {
          this.emitHeight();
        }, false);
      }, 300);
    }
  },
  methods: {
    loading() {
      const loading = ElLoading.service({
        // lock: true,
        target: this.$refs.productContainer,
        fullscreen: false,
        text: "Loading"
      });
      setTimeout(() => {
        loading.close();
      }, 300);
    },
    toProductDetail() {
      if (this.isEnOrZh === "zh" & !this.ElNotificationshow) {
        ElNotification({
          title: "提示",
          message: "请通过商城点击链接查看产品详情。",
          duration: 15000,
          onClose: () => {
            this.$router.push({
              name: "shop"
            });
          },
          position: "bottom-right"
        });
        this.ElNotificationshow = true;
      }
      if (this.isEnOrZh === "en" & !this.ElNotificationshow) {
        ElNotification({
          title: "Tips",
          message: "Please click on the link through the shop to view product details.",
          duration: 15000,
          onClose: () => {
            this.$router.push({
              name: "shop"
            });
          },
          position: "bottom-right"
        });
        this.ElNotificationshow = true;
      }
    },
    emitHeight() {
      this.$nextTick(() => {
        if (this.$refs.productContainer) {
          this.$emit("productItemHeight", this.$refs.productContainer.offsetHeight);
        }
      });
    }
  },
  beforeRouteLeave() {
    window.removeEventListener("resize");
  }
};