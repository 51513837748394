import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ProductItem = _resolveComponent("ProductItem");
  return _openBlock(), _createElementBlock("div", {
    ref: "productContainer",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.toProductDetail && $options.toProductDetail(...args))
  }, [$data.isEnOrZh === 'zh' ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "row",
    style: _normalizeStyle({
      flexWrap: $props.wrap ? 'wrap' : 'nowrap'
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.products, (product, index) => {
    return _openBlock(), _createBlock(_component_ProductItem, {
      key: index,
      model: product.model,
      name: product.zh_name,
      img: product.img,
      lazy: $props.lazy
    }, null, 8, ["model", "name", "img", "lazy"]);
  }), 128))], 4)) : _createCommentVNode("", true), $data.isEnOrZh === 'en' ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "row",
    style: _normalizeStyle({
      flexWrap: $props.wrap ? 'wrap' : 'nowrap'
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.products, (product, index) => {
    return _openBlock(), _createBlock(_component_ProductItem, {
      key: index,
      model: product.model,
      name: product.en_name,
      img: product.img,
      lazy: $props.lazy
    }, null, 8, ["model", "name", "img", "lazy"]);
  }), 128))], 4)) : _createCommentVNode("", true)], 512);
}