import Menunav from "@/components/Common/Menu.vue";
import Footernav from "@/components/Common/Footer.vue";
import MenuDrawer from "./components/Common/MenuDrawer.vue";
export default {
  name: "app",
  components: {
    Menunav,
    Footernav,
    MenuDrawer
  },
  data() {
    return {
      menuDrawerOpen: false
    };
  },
  computed: {
    key() {
      return this.$route.path + Math.random();
    },
    isHome() {
      return this.$route.name == "home";
    }
  },
  methods: {
    changeMenuSwitch(data) {
      this.menuDrawerOpen = data;
    }
  }
};