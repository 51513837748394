export default {
  props: {
    buyList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isEnOrZh: window.localStorage.getItem("language")
    };
  }
};