import "core-js/modules/es.array.push.js";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      showLanguages: false,
      showProducts: false,
      showAbouts: false,
      hideMenus: false,
      isHome: false
    };
  },
  props: {
    isDarkColor: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      productsBrand: "getProductsBrand"
    })
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.hideMenu();
    }, false);
  },
  methods: {
    ...mapActions(["changeProductsBrand", "changeCategoriesChoose"]),
    toProduct(data) {
      if (this.$route.name == "products" && this.productsBrand == data) {
        return;
      } else {
        this.changeProductsBrand(data);
        this.changeCategoriesChoose("color_pencil");
        this.$router.push({
          name: "products",
          params: {
            brand: data
          }
        });
      }
    },
    toAbout(data) {
      this.$router.push({
        name: data
      });
    },
    showLanguage(data) {
      if (data) {
        this.showLanguages = data;
      } else {
        this.showLanguages = !this.showLanguages;
      }
    },
    showProduct(data) {
      if (data) {
        this.showProducts = data;
      } else {
        this.showProducts = !this.showProducts;
      }
    },
    showAbout(data) {
      if (data) {
        this.showAbouts = data;
      } else {
        this.showAbouts = !this.showAbouts;
      }
    },
    changeLanguage(data) {
      let language = window.localStorage.getItem("language");
      if (language != data) {
        window.localStorage.setItem("language", data);
        location.reload();
      }
    },
    hideMenu() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if (scrollTop != 0) {
        this.hideMenus = true;
      } else {
        this.hideMenus = false;
      }
    }
  }
};