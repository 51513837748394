import MixImageSwiperVue from "@/components/Common/MixImageSwiper.vue";
import MixImageSwiperVue1 from "@/components/Common/MixImageSwiper.vue";
export default {
  components: {
    MixImageSwiperVue,
    MixImageSwiperVue1
  },
  data() {
    return {
      isEnOrZh: window.localStorage.getItem("language")
    };
  }
};