import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "mix-image-swiper"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_carousel_item = _resolveComponent("el-carousel-item");
  const _component_el_carousel = _resolveComponent("el-carousel");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_carousel, {
    interval: 5000,
    arrow: "always",
    height: $data.imageSwiperHeight + 'px'
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.swiperNum, (arr, index) => {
      return _openBlock(), _createBlock(_component_el_carousel_item, {
        height: "auto",
        key: index
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "flex",
          ref_for: true,
          ref: "imageSwiperRef"
        }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.ImageNum, num => {
          return _openBlock(), _createElementBlock("img", {
            class: "imageSwiper",
            style: _normalizeStyle([{
              'max-width': 100 / $props.ImageNum + '%'
            }, {
              'flex-basis': 100 / $props.ImageNum + '%'
            }]),
            key: num,
            src:
            // eslint-disable-next-line prettier/prettier
            require('@/assets/' + $props.file + ((arr - 1) * $props.ImageNum + num) + '.jpg')
          }, null, 12, _hoisted_2);
        }), 128))], 512)]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  }, 8, ["height"])]);
}