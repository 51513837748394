import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = {
  class: "pb-40"
};
const _hoisted_3 = {
  class: "detail-title"
};
const _hoisted_4 = {
  class: "detail-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_card = _resolveComponent("el-card");
  const _component_el_timeline_item = _resolveComponent("el-timeline-item");
  const _component_el_timeline = _resolveComponent("el-timeline");
  const _component_ProductsList = _resolveComponent("ProductsList");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("about.nyoni.title")), 1), _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("about.nyoni.content")), 1)]), _createVNode(_component_el_timeline, null, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.activities, (activity, index) => {
      return _openBlock(), _createBlock(_component_el_timeline_item, {
        key: index,
        timestamp: activity.timestamp,
        placement: "top"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_card, null, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(activity.content[$data.isEnOrZh]), 1)]),
          _: 2
        }, 1024)]),
        _: 2
      }, 1032, ["timestamp"]);
    }), 128))]),
    _: 1
  }), _createVNode(_component_ProductsList, {
    class: "pt-80"
  })]);
}