const feiyanproductsList = {
  color_pencil: [
    {
      model: "FY3001",
      en_name:
        "FEIYAN-FY3001 Art Standard Kids Color Pencil Set With 12/24/36/48 Colors",
      zh_name: "FEIYAN-FY3001 飞雁儿童彩色铅笔 12/24/36/48色",
    },
    {
      model: "JL51",
      en_name:
        "FEIYAN-JL51 Art Standard High Grade Kids Color Pencil Set With 12/24/36/48 Colors",
      zh_name: "FEIYAN-JL51 飞雁儿童高级彩色铅笔 12/24/36/48色",
    },
    // {
    //   model: "JL82",
    //   en_name:
    //     "FEIYAN-JL82 Art Standard Medium Grade Kids Color Pencil Set With 12/24/36/48 Colors",
    //   zh_name: "FEIYAN-JL82 飞雁儿童中级彩色铅笔 12/24/36/48色",
    // },
    {
      model: "JL83",
      en_name:
        "FEIYAN-JL83 Art Standard Erasable Color Pencil Set With 12/24/36/48 Colors",
      zh_name: "FEIYAN-JL83 飞雁可擦彩色铅笔 12/24/36/48色",
    },
  ],
  school_office_pencil: [
    {
      model: "FY1009",
      en_name: "FEIYAN-FY1009 HB Round Cute Pencil",
      zh_name: "FEIYAN-FY1009 飞雁圆杆套膜可爱系铅笔",
    },

    {
      model: "FY1010",
      en_name: "FEIYAN-FY1010 HB Round Cute Pencil",
      zh_name: "FEIYAN-FY1010 飞雁圆杆企鹅套膜可爱系铅笔",
    },
    {
      model: "FY1011",
      en_name: "FEIYAN-FY1011 HB Round Cute Pencil",
      zh_name: "FEIYAN-FY1011 飞雁圆杆仓鼠套膜可爱系铅笔",
    },
    {
      model: "FY1012",
      en_name: "FEIYAN-FY1012 HB Round Cute Pencil",
      zh_name: "FEIYAN-FY1012 飞雁圆杆拾贝季套膜可爱系铅笔",
    },
    // {
    //   model: "FY1013",
    //   en_name: "FEIYAN-FY1013 HB Round Cute Pencil",
    //   zh_name: "FEIYAN-FY1013 飞雁圆杆狗狗套膜可爱系铅笔",
    // },
    // {
    //   model: "FY1014",
    //   en_name: "FEIYAN-FY1014 HB Round Cute Pencil",
    //   zh_name: "FEIYAN-FY1014 飞雁圆杆草莓季节套膜可爱系铅笔",
    // },
    {
      model: "FY1015",
      en_name: "FEIYAN-FY1015 HB Triangle Pole Hole Hole Pencil",
      zh_name: "FEIYAN-FY1015 飞雁三角杆HB三色洞洞笔",
    },
    {
      model: "FY1016",
      en_name: "FEIYAN-FY1016 2B Blue Triangle Pole Hole Hole Pencil",
      zh_name: "FEIYAN-FY1016 飞雁蓝色三角杆2B三色洞洞笔",
    },
    {
      model: "FY1017",
      en_name:
        "FEIYAN-FY1017 2B Triangle Article Extraction Five Colors Mix Pencil",
      zh_name: "FEIYAN-FY1017 飞雁三角杆2B抽条5色混装洞洞笔",
    },
    {
      model: "JL1233",
      en_name: "FEIYAN-JL1233 Hexagon HB Pencil",
      zh_name: "FEIYAN-JL1233 飞雁六角杆考试HB铅笔",
    },
    {
      model: "JL1234",
      en_name: "FEIYAN-JL1234 Hexagon HB Pencil",
      zh_name: "FEIYAN-JL1234 飞雁六角杆考试HB铅笔",
    },
    {
      model: "FY2001",
      en_name: "FEIYAN-FY2001 Black Color Hexagon 2B Pencil",
      zh_name: "FEIYAN-FY2001 飞雁哑光黑六角杆2B铅笔带卷笔刀",
    },
    {
      model: "FY2005",
      en_name: "FEIYAN-FY2005 Hexagon Yellow 2B Pencil",
      zh_name: "FEIYAN-FY2005 飞雁六角杆2B铅笔亮光黄杆带卷笔刀",
    },
    {
      model: "FY2006",
      en_name: "FEIYAN-FY2006 Wood Color Hexagon HB Pencil",
      zh_name: "FEIYAN-FY2006 飞雁原木色六角杆HB铅笔带卷笔刀",
    },
    {
      model: "FY2007",
      en_name: "FEIYAN-FY2007 Hexagon HB Pencil",
      zh_name: "FEIYAN-FY2007 飞雁六角杆黄黑抽条铅笔",
    },
    {
      model: "FY2009",
      en_name: "FEIYAN-FY2009 Hexagon HB Pencil",
      zh_name: "FEIYAN-FY2009 飞雁六角杆抽条HB铅笔",
    },
    {
      model: "FY2010",
      en_name: "FEIYAN-FY2010 Red Hexagon HB Pencil",
      zh_name: "FEIYAN-FY2010 飞雁考试专用铅笔",
    },
    {
      model: "JL0198",
      en_name: "FEIYAN-JL0198 High Grade Round Drawing HB Pencil",
      zh_name: "FEIYAN-JL0198 飞雁高级绘图铅笔",
    },
    {
      model: "JL311",
      en_name: "FEIYAN-JL311 Triangle HB Pencil",
      zh_name: "FEIYAN-JL311 飞雁三角杆HB沾顶洞洞笔",
    },

    {
      model: "JL312",
      en_name: "FEIYAN-JL312 Triangle HB Pencil",
      zh_name: "FEIYAN-JL312 飞雁三角杆HB沾顶洞洞笔",
    },
    {
      model: "JL1700",
      en_name: "FEIYAN-JL1700 Yellow Hexagon HB Pencil",
      zh_name: "FEIYAN-JL1700 飞雁六角杆高级书写HB铅笔",
    },
    {
      model: "JL1701",
      en_name: "FEIYAN-JL1701 Yellow Hexagon 2B Pencil",
      zh_name: "FEIYAN-JL1701 飞雁六角杆高级书写2B铅笔",
    },
    {
      model: "JL1800",
      en_name: "FEIYAN-JL1800 Black Hexagon HB Pencil",
      zh_name: "FEIYAN-JL1800 飞雁六角杆黑杆高级书写HB铅笔",
    },
    {
      model: "JL1801",
      en_name: "FEIYAN-JL1801 Yellow Hexagon 2B Pencil",
      zh_name: "FEIYAN-JL1801 飞雁六角杆黑杆高级书写2B铅笔",
    },
    // {
    //   model: "JL1810",
    //   en_name: "FEIYAN-JL1810 Yellow Hexagon HB Pencil",
    //   zh_name: "FEIYAN-JL1810 飞雁圆杆卡通HB铅笔",
    // },
    {
      model: "JL1822",
      en_name: "FEIYAN-JL1822 Triangle HB Pencil",
      zh_name: "FEIYAN-JL1822 飞雁三角杆HB铅笔",
    },
    {
      model: "JL1828",
      en_name: "FEIYAN-JL1828 High Grade Hexagon HB Pencil",
      zh_name: "FEIYAN-JL1828 飞雁六角杆高级书写HB铅笔",
    },
    {
      model: "JL1830",
      en_name: "FEIYAN-JL1830 Hexagon HB Pencil",
      zh_name: "FEIYAN-JL1830 飞雁六角杆水果系列HB铅笔",
    },
    {
      model: "JL1832",
      en_name: "FEIYAN-JL1832 Triangle HB Pencil",
      zh_name: "FEIYAN-JL1832 飞雁三角杆考试考试专用HB铅笔",
    },

    {
      model: "JL1833",
      en_name: "FEIYAN-JL1833 Hexagon HB Pencil",
      zh_name: "FEIYAN-JL1833 飞雁六角杆蔬菜系列HB铅笔",
    },
    {
      model: "JL1839",
      en_name: "FEIYAN-JL1839 Triangle HB Pencil",
      zh_name: "FEIYAN-JL1839 飞雁三角杆HB铅笔",
    },

    {
      model: "JL1868",
      en_name: "FEIYAN-JL1868 High Grade Hexagon 2B Pencil",
      zh_name: "FEIYAN-JL1868 飞雁六角杆高级书写2B铅笔",
    },
    // {
    //   model: "JL901",
    //   en_name: "FEIYAN-JL1839 Colored HB Pencil",
    //   zh_name: "FEIYAN-JL1839 飞雁时尚炫彩铅笔",
    // },
    {
      model: "JL6151",
      en_name: "FEIYAN-JL6151 Hexagon HB Pencil",
      zh_name: "FEIYAN-JL6151 飞雁六角杆黄黑抽条铅笔",
    },
    {
      model: "JL6158",
      en_name: "FEIYAN-JL6158 Triangle HB Pencil",
      zh_name: "FEIYAN-JL6158 飞雁三角杆抽条哑光HB铅笔",
    },
    {
      model: "JL8003",
      en_name: "FEIYAN-JL8003 High Grade Round HB Pencil",
      zh_name: "FEIYAN-JL8003 飞雁深绿圆杆高级书写HB铅笔",
    },
    {
      model: "JL8004",
      en_name: "FEIYAN-JL8004 High Grade Hexagon HB Pencil",
      zh_name: "FEIYAN-JL8004 飞雁银杆六角杆高级书写HB铅笔",
    },
    {
      model: "JL8008",
      en_name: "FEIYAN-JL8008 Special Grade Round HB Pencil",
      zh_name: "FEIYAN-JL8008 飞雁棕色圆杆特级书写HB铅笔",
    },
    {
      model: "JL8109",
      en_name: "FEIYAN-JL8109 36 Pcs Bucket Three Colors Hexagon HB Pencil",
      zh_name: "FEIYAN-JL8109 3色36支筒装六角杆铅笔",
    },
    // {
    //   model: "JL8112",
    //   en_name: "FEIYAN-JL8112 Round HB Pencil",
    //   zh_name: "FEIYAN-JL8112 飞雁吸卡10支闪光HB铅笔",
    // },
    {
      model: "FY1001",

      en_name: "FEIYAN-FY1001 HB Triangle Pencil",
      zh_name: "FEIYAN-FY1001 飞雁马卡龙小皮头三角杆HB洞洞笔",
    },
    {
      model: "FY1002",
      en_name: "FEIYAN-FY1002 Triangle HB Pencil",
      zh_name: "FEIYAN-FY1002 飞雁马卡龙大皮头三角杆HB洞洞笔",
    },
    {
      model: "FY1005",
      en_name: "FEIYAN-FY1005 Hexagon HB Pencil",
      zh_name: "FEIYAN-FY1005 飞雁六角杆HB铅笔带卷笔刀",
    },
    {
      model: "FY1006",
      en_name: "FEIYAN-FY1006 HB Triangle Pencil",
      zh_name: "FEIYAN-FY1006 飞雁三角杆HB抽条洞洞笔",
    },

    {
      model: "JL8116",
      en_name: "FEIYAN-JL8116 Triangle HB Pencil",
      zh_name: "FEIYAN-JL8116 飞雁吸卡10支珠光HB铅笔",
    },
    // {
    //   model: "JL8120",
    //   en_name: "FEIYAN-JL8120 Round HB Pencil",
    //   zh_name: "FEIYAN-JL8120 飞雁印花HB铅笔",
    // },
    {
      model: "JL8121",
      en_name: "FEIYAN-JL8121 Wood Color Hexagon HB Pencil",
      zh_name: "FEIYAN-JL8121 飞雁原木色36支筒装六角杆铅笔",
    },
    // {
    //   model: "JL8810",
    //   en_name: "FEIYAN-JL8810 HB Pencil",
    //   zh_name: "FEIYAN-JL8810 飞雁六角杆红黑抽条大皮头HB铅笔",
    // },

    // {
    //   model: "JL1852",
    //   en_name: "FEIYAN-JL1852 Triangle HB Pencil",
    //   zh_name: "FEIYAN-JL1852 飞雁香味防滑皮头HB铅笔",
    // },
    // {
    //   model: "JL1232",
    //   en_name: "FEIYAN-JL1232 Triangle HB Pencil",
    //   zh_name: "FEIYAN-JL1232 飞雁三角杆HB铅笔",
    // },

    // {
    //   model: "JL6159",
    //   en_name: "FEIYAN-JL6159 Round HB Pencil",
    //   zh_name: "FEIYAN-JL6159 飞雁吸卡三角抽条皮头HB",
    // },
    // {
    //   model: "JL8115",
    //   en_name: "FEIYAN-JL8115 Round HB Pencil",
    //   zh_name: "FEIYAN-JL8115 飞雁吸卡三角抽条皮头HB",
    // },

    // {
    //   model: "JL1858",
    //   en_name: "FEIYAN-JL1858 Round HB Pencil",
    //   zh_name: "FEIYAN-JL1858 飞雁三角抽条皮头HB",
    // },
    // {
    //   model: "JL303",
    //   en_name: "FEIYAN-JL303 Round HB Pencil",

    //   zh_name: "FEIYAN-JL303 飞雁高级书写HB铅笔",
    // },
    // {
    //   model: "JL304",
    //   en_name: "FEIYAN-JL304 Round HB Pencil",
    //   zh_name: "FEIYAN-JL304 飞雁高级书写HB铅笔",
    // },
    // // {
    // //   model: "JL1235",
    // //   en_name: "FEIYAN-JL1235 Hexagon HB Pencil",
    // //   zh_name: "FEIYAN-JL1235 飞雁六角杆HB铅笔",
    // // },
    // {
    //   model: "JL1230",
    //   en_name: "FEIYAN-JL1230 Round HB Pencil",
    //   zh_name: "FEIYAN-JL1230 飞雁原木雕花HB铅笔",
    // },

    // {
    //   model: "JL1820",
    //   en_name: "FEIYAN-JL1820 Triangle HB Pencil",
    //   zh_name: "FEIYAN-JL1820 飞雁三角杆HB铅笔",
    // },
    // {
    //   model: "JL1823",
    //   en_name: "FEIYAN-JL1823 Triangle 2B Pencil",
    //   zh_name: "FEIYAN-JL1823 飞雁三角杆高级书写2B铅笔",
    // },
    // // {
    // //   model: "FY6151",
    // //   en_name: "FEIYAN-FY6151 Hexagon HB Pencil",
    // //   zh_name: "FEIYAN-FY6151 飞雁六角杆红黑抽条HB铅笔",
    // // },
    // {
    //   model: "FY1800",
    //   en_name: "FEIYAN-FY1800 Black Hexagon HB Pencil",
    //   zh_name: "FEIYAN-FY1800 飞雁六角杆黑杆HB铅笔",
    // },
    // {
    //   model: "FY1801",
    //   en_name: "FEIYAN-FY1801 Black Hexagon 2B Pencil",
    //   zh_name: "FEIYAN-FY1801 飞雁六角杆黑杆2B铅笔",
    // },
    // {
    //   model: "JL1850",
    //   en_name: "FEIYAN-JL1850 Hexagon HB Pencil",
    //   zh_name: "FEIYAN-JL1850 飞雁六角杆梅兰竹菊HB铅笔",
    // },
    // {
    //   model: "JL1851",
    //   en_name: "FEIYAN-JL1851 Hexagon HB Pencil",
    //   zh_name: "FEIYAN-JL1851 飞雁六角杆梅兰竹菊HB铅笔",
    // },

    // {
    //   model: "FY1812",
    //   en_name: "FEIYAN-FY1812 HB Pencil",
    //   zh_name: "FEIYAN-FY1812 飞雁圆杆HB铅笔",
    // },

    // {
    //   model: "FY8105",
    //   en_name: "FEIYAN-FY8105 Triangle HB Pencil",
    //   zh_name: "FEIYAN-FY8105 飞雁三角杆HB铅笔",
    // },
    // {
    //   model: "FY1821",
    //   en_name: "FEIYAN-FY1821 Hexagon HB Pencil",
    //   zh_name: "FEIYAN-FY1821 飞雁六角杆HB铅笔",
    // },

    // {
    //   model: "FY8102",
    //   en_name: "FEIYAN-FY8102 HB Pencil",
    //   zh_name: "FEIYAN-FY8102 飞雁20支装大皮头HB铅笔",
    // },
  ],
};

export default feiyanproductsList;
