import BuyList from "./BuyList.vue";
export default {
  name: "NYONI",
  components: {
    BuyList
  },
  data() {
    return {
      isEnOrZh: window.localStorage.getItem("language"),
      buyList: [{
        content: {
          en: "NYONI Tmall Flagship Store",
          zh: "尼奥尼天猫旗舰店"
        },
        buyType: "tianmao",
        buyLink: "https://nyoni.tmall.com"
      }, {
        content: {
          en: "NYONI Jingdong Flagship Store",
          zh: "尼奥尼京东旗舰店"
        },
        buyType: "jingdong",
        buyLink: "https://mall.jd.com/index-982568.html"
      }, {
        content: {
          en: "NYONI Pinduoduo Flagship Store",
          zh: "尼奥尼拼多多旗舰店"
        },
        buyType: "pinduoduo",
        buyLink: "http://mobile.pinduoduo.com/mall_page.html?mall_id=609785812"
      }, {
        content: {
          en: "NYONI Alibaba Hangzhou Manqini Stationery Co.,Ltd",
          zh: "尼奥尼 阿里巴巴国际站 杭州曼奇尼文化用品有限公司"
        },
        buyType: "alibaba",
        buyLink: "https://hzmanqini.en.alibaba.com/"
      }]
    };
  }
};