export default {
  data() {
    return {};
  },
  mounted() {
    window.addEventListener("touchstart", () => {
      this.playVideo();
    }, false);
    window.addEventListener("touchmove", () => {
      this.playVideo();
    }, false);
    window.addEventListener("touchend", () => {
      this.playVideo();
    }, false);
  },
  onBeforeUnmount() {
    console.log("2222");
  },
  methods: {
    playVideo() {
      const video = document.getElementById("bgvid");
      if (video) {
        video.play();
      }
    }
  }
};