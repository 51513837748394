import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8d02aafe"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_carousel_item = _resolveComponent("el-carousel-item");
  const _component_el_carousel = _resolveComponent("el-carousel");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_carousel, {
    "indicator-position": "outside",
    height: $data.bannerHeight + 'px',
    ref: "carouselBanner",
    id: "carouselBanner",
    autoplay: $data.autoplay
  }, {
    default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, item => {
      return _createVNode(_component_el_carousel_item, {
        key: item,
        height: "auto",
        interval: 5000
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          ref_for: true,
          ref: "bannerHeight",
          src: require(`@/assets/banner/${$data.size}/${$data.isEnOrZh}${item}.jpg`),
          alt: "",
          onLoad: _cache[0] || (_cache[0] = (...args) => $options.imgLoad && $options.imgLoad(...args)),
          style: {
            "width": "100%"
          }
        }, null, 40, _hoisted_1)]),
        _: 2
      }, 1024);
    }), 64))]),
    _: 1
  }, 8, ["height", "autoplay"])]);
}