const nyoniProductsList = {
  color_pencil: [
    {
      model: "N8520",
      en_name:
        "NYONI-N8520 Professional Art Standard Oily Color Pencil Set With 24/36/48/72/120 Colors",
      zh_name: "NYONI-N8520 尼奥尼专业级油性彩色铅笔 24/36/48/72/120色",
    },
    {
      model: "N8535",
      en_name:
        "NYONI-N8535 Professional Art Standard 24 Colors People Skin Tints Oily Color Pencil Set",
      zh_name: "NYONI-N8535 尼奥尼专业级24色人像 肤色系油性彩色铅笔",
    },
    {
      model: "N28",
      en_name:
        "NYONI-N28 Professional Art Standard Oily Color Pencil Set With 12/24/36/48/72/120 Colors",
      zh_name: "NYONI-N28 尼奥尼专业级油性彩色铅笔 12/24/36/48/72/120色",
    },
    {
      model: "N2829",
      en_name:
        "NYONI-N2829 Professional Art Standard Drawing Soft Pastel Color Chalk With 12/24/48 colors",
      zh_name: "NYONI-N2829 尼奥尼专业级彩色粉笔 12/24/48色",
    },
  ],
  sketch_pencil: [
    {
      model: "N2800",
      en_name:
        "NYONI-N2800 Professional Art Standard Drawing Sketch Pencil Set",
      zh_name: "NYONI-N2800 尼奥尼专业绘图铅笔",
    },
    {
      model: "N2801_14B16B",
      en_name: "NYONI-N2801_14B16B Professional Art 14B 16B Matt Sketch Pencil",
      zh_name: "NYONI-N2801_14B16B 尼奥尼哑光素描铅笔14B16B",
    },
    {
      model: "N8128",
      en_name:
        "NYONI-N8128 Professional Art Standard Drawing Sketch Pencil Set",
      zh_name: "NYONI-N8128 尼奥尼专业高档绘图铅笔",
    },
    {
      model: "N2802",
      en_name:
        "NYONI-N2802 Professional Art Standard Drawing New Sketch Pencil Set",
      zh_name: "NYONI-N2802 尼奥尼专业浪时代绘图铅笔",
    },
    {
      model: "N8136_29",
      en_name:
        "NYONI-N8136-29 Professional Art Standard Drawing Sketch Pencil Set",
      zh_name: "NYONI-N8136-29 尼奥尼专业铁盒素描套装",
    },
    {
      model: "N8136_30",
      en_name:
        "NYONI-N8136-30 Professional Art Standard Drawing Sketch Pencil Set",
      zh_name: "NYONI-N8136-30 尼奥尼专业笔帘素描套装",
    },

    {
      model: "N2806",
      en_name:
        "NYONI-N2806 Professional Art Standard Drawing Sketch Pencil Set",
      zh_name: "NYONI-N2806 尼奥尼哑光绘画铅笔套装",
    },
    {
      model: "N2806_ironbox",
      en_name:
        "NYONI-N2806 Professional Art Standard Drawing Sketch Pencil Iron Box Set",
      zh_name: "NYONI-N2806 尼奥尼哑光绘画铅笔铁盒套装",
    },
  ],
  charcoal_pencil: [
    {
      model: "N2801",
      en_name:
        "NYONI-N2801 Professional Art Standard Drawing Charcoal Pencil Set",
      zh_name: "NYONI-N2801 尼奥尼专业绘画炭笔",
    },
    {
      model: "N2801_sketch",
      en_name:
        "NYONI-N2801 Professional Art Standard Sketch Charcoal Pencil Set",
      zh_name: "NYONI-N2801 尼奥尼扁头速写炭笔",
    },
    {
      model: "N2802",
      en_name:
        "NYONI-N2802 Professional Art Standard Drawing Charcoal Pencil Set",
      zh_name: "NYONI-N2802 尼奥尼专业浪时代系列绘画炭笔",
    },
    {
      model: "N2828",
      en_name:
        "NYONI N2828 Professional Art Standard Four Colors Charcoal Pencil Set",
      zh_name: "NYONI N2828 尼奥尼专业级4色彩色炭笔",
    },
    {
      model: "N2830",
      en_name:
        "NYONI N2830 Professional Art Standard 24 Colors Sketch Color Charcoal Pencil Set",
      zh_name: "NYONI N2830 尼奥尼专业级24色素描彩色炭笔",
    },
    {
      model: "N2802_charcoal_sketch",
      en_name:
        "NYONI-N2802 Professional Art Standard Drawing Charcoal Pencil Set",
      zh_name: "NYONI-N2802 尼奥尼速写素描系列绘画炭笔",
    },
    {
      model: "N8129",
      en_name:
        "NYONI-N8129 Professional Art Standard Drawing Hexagon Charcoal Pencil Set",
      zh_name: "NYONI-N8129 尼奥尼专业六角绘画炭笔",
    },
    // {
    //   model: "N3032",
    //   en_name:
    //     "NYONI-N3032 Professional Art Standard Drawing Round Charcoal Pencil Set",
    //   zh_name: "NYONI-N3032 尼奥尼廊桥黑杆混装专业绘画炭笔",
    // },
    {
      model: "N8109",
      en_name:
        "NYONI-N8109 Professional Art Standard Drawing New Hexagon Charcoal Pencil Set",
      zh_name: "NYONI-N8109 尼奥尼专业六角新款绘画炭笔",
    },
    {
      model: "N2805",
      en_name:
        "NYONI-N2805 Professional Art Standard Drawing Hexagon Charcoal Pencil Set",
      zh_name: "NYONI-N2805 尼奥尼绿韵系列水性漆专业六角绘画炭笔",
    },
    // {
    //   model: "N2803",
    //   en_name:
    //     "NYONI-N2803 Professional Art Standard Drawing Triangle Hole Charcoal Pencil Set",
    //   zh_name: "NYONI-N2803 尼奥尼专业大三角洞洞绘画炭笔",
    // },
    // {
    //   model: "N8207",
    //   en_name:
    //     "NYONI-N8207 Professional Art Standard Drawing Stay Charcoal Pencil Set",
    //   zh_name: "NYONI-N8207 尼奥尼专业九月拉线绘画炭笔",
    // // },
    // {
    //   model: "N8138",
    //   en_name:
    //     "NYONI-N8138 Professional Art Standard Drawing S.Soft Charcoal Pencil Set",
    //   zh_name: "NYONI-N8138 尼奥尼专业九月特软绘画炭笔",
    // },
    {
      model: "N8135",
      en_name:
        "NYONI-N8135 Professional Art Standard Round Willow Article Charcoal",
      zh_name: "NYONI-N8135 尼奥尼专业圆形柳木碳条",
    },
    {
      model: "N8137",
      en_name:
        "NYONI-N8137 Professional Art Standard Square Willow Article Charcoal",
      zh_name: "NYONI-N8137 尼奥尼专业方形柳木碳条",
    },
  ],
  eraser: [
    {
      model: "N2810",
      en_name: "NYONI-N2810 Professional Art Standard 6Pcs Eraser Pen",
      zh_name: "NYONI-N2810 尼奥尼专业6支装橡皮笔",
    },
    {
      model: "N2820",
      en_name:
        "NYONI-N2820 Professional Art Standard 12Pcs Eraser Pen With Brush",
      zh_name: "NYONI-N2820 尼奥尼专业12支装带毛刷橡皮笔",
    },
    {
      model: "N8515",
      en_name: "NYONI-N8515 Professional Art Ultrathin Eraser",
      zh_name: "NYONI-N8515 尼奥尼薄锐超薄橡皮",
    },
    // {
    //   model: "N8516",
    //   en_name: "NYONI-N8516 Professional Art Ultrathin Eraser",
    //   zh_name: "NYONI-N8516 尼奥尼薄锐超薄橡皮",
    // },
    // {
    //   model: "N8517",
    //   en_name: "NYONI-N8517 Professional Art Ultrathin Eraser",
    //   zh_name: "NYONI-N8517 尼奥尼薄锐超薄橡皮",
    // },
    // {
    //   model: "N8132",
    //   en_name: "NYONI-N8132 Professional Art Eraser",
    //   zh_name: "NYONI-N8132 尼奥尼专业绘画橡皮",
    // },
    {
      model: "N8511",
      en_name: "NYONI-N8511 Professional Art Eraser",
      zh_name: "NYONI-N8511 尼奥尼专业绘画橡皮",
    },
    // {
    //   model: "N8512",
    //   en_name: "NYONI-N8512 Professional Art Eraser",
    //   zh_name: "NYONI-N8512 尼奥尼专业绘画橡皮",
    // },
    {
      model: "N8133",
      en_name: "NYONI-N8133 Professional Art Kneadable Eraser",
      zh_name: "NYONI-N8133 尼奥尼专业可塑橡皮",
    },
  ],
  other_tools: [
    {
      model: "N2831",
      en_name: "NYONI-N2831 Professional Art Color Pastels For Drawing",
      zh_name: "NYONI-N2831 尼奥尼艺术绘画色粉",
    },
    {
      model: "N8526",
      en_name: "NYONI-N8526 Professional Art Sketch Wipe Cotton",
      zh_name: "NYONI-N8526 尼奥尼素描揉擦刀",
    },
    {
      model: "N8525",
      en_name: "NYONI-N8525 Professional Art Dryer For Watercolor Drawing",
      zh_name: "NYONI-N8525 尼奥尼色彩风干机",
    },
    {
      model: "N8211",
      en_name: "NYONI-N8211 Professional Art Sketch Book",
      zh_name: "NYONI-N8211 尼奥尼素描本",
    },
    {
      model: "N8213",
      en_name: "NYONI-N8213 Professional Art 180g Color Pencil Paper",
      zh_name: "NYONI-N8213 尼奥尼 180g 彩铅专用纸",
    },
    {
      model: "N8201",
      en_name: "NYONI-N8201 Professional Art 180g Sketch Paper",
      zh_name: "NYONI-N8201 尼奥尼180g素描纸",
    },
    // {
    //   model: "N8301",
    //   en_name: "NYONI-N8301 Professional Art 8K 100g Sketch Paper",
    //   zh_name: "NYONI-N8301 尼奥尼 8K 100g 素写纸",
    // },
    {
      model: "N8302",
      en_name: "NYONI-N8302 Professional Art 4K 8K 180g Sketch Paper",
      zh_name: "NYONI-N8302 尼奥尼 4K 8K 180g 素描纸",
    },
    // {
    //   model: "N8303",
    //   en_name: "NYONI-N8303 Professional Art 4K 8K 160g Sketch Paper",
    //   zh_name: "NYONI-N8303 尼奥尼 4K 8K 160g 素描纸",
    // },
    // 其他
    {
      model: "N8209",
      en_name: "NYONI-N8209 Professional Art Standard Highlight Pen",
      zh_name: "NYONI-N8209 尼奥尼手绘高光笔",
    },
    {
      model: "N8208",
      en_name: "NYONI-N8208 Professional Art Sharpener",
      zh_name: "NYONI-N8208 尼奥尼手摇素描削笔器",
    },
    {
      model: "N8508",
      en_name: "NYONI-N8508 Professional Art Sketch Sharpener",
      zh_name: "NYONI-N8508 尼奥尼手摇速写削笔器",
    },
    {
      model: "N8608",
      en_name: "NYONI-N8508 Professional Art Sketch Sharpener",
      zh_name: "NYONI-N8508 尼奥尼速写削笔器",
    },
    {
      model: "N8308",
      en_name: "NYONI-N8508 Professional Art Sketch Sharpener",
      zh_name: "NYONI-N8508 尼奥尼黑白素描削笔器",
    },
    // {
    //   model: "N8523",
    //   en_name: "NYONI-N8523 Professional Art Paper Pen",
    //   zh_name: "NYONI-N8523 尼奥尼素描纸擦笔",
    // },
    // {
    //   model: "N8139",
    //   en_name: "NYONI-N8139 Professional Art Painting Brush",
    //   zh_name: "NYONI-N8139 尼奥尼九月水粉笔",
    // },
    {
      model: "N8521",
      en_name: "NYONI-N8521 Professional Art Color Pencil Clean Brush",
      zh_name: "NYONI-N8521 尼奥尼彩铅专用刷",
    },

    {
      model: "N8210",
      en_name: "NYONI-N8210 Professional Art Adhesive Tape",
      zh_name: "NYONI-N8210 尼奥尼素美纹纸胶带",
    },
    {
      model: "N8215",
      en_name: "NYONI-N8215 Professional Art Standard Rub Cotton",
      zh_name: "NYONI-N8215 尼奥尼专业绘画揉擦棉",
    },
    {
      model: "N2834",
      en_name: "NYONI-N2834 Professional Art Knife",
      zh_name: "NYONI-N2834 尼奥尼美工刀",
    },
    // {
    //   model: "N2834-A",
    //   en_name: "NYONI-N2834-A Professional Art Knife Blade",
    //   zh_name: "NYONI-N2834-A 尼奥尼美工刀刀片",
    // },
    {
      model: "N8522",
      en_name: "NYONI-N8522 Professional Art Metal Press Extender",
      zh_name: "NYONI-N8522 尼奥尼金属按压延长器",
    },
  ],
};

export default nyoniProductsList;
