function setRem() {
  let baseSize = 14; // 32
  const clientWidth = document.documentElement.clientWidth; // 750
  if (clientWidth >= 768) {
    baseSize = 16;
  }
  if (clientWidth >= 1400) {
    baseSize = 18;
  }
  document.documentElement.style.fontSize = baseSize + "px";
}
setRem();
window.onresize = () => {
  setRem();
};
