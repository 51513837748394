import "core-js/modules/es.array.push.js";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      menuSwitch: false,
      // 默认隐藏 0隐藏 1显示
      isEnOrZh: window.localStorage.getItem("language"),
      hideMenus: false,
      activeName: "",
      menu: {
        products: {
          en: "Products",
          zh: "产品"
        },
        about: {
          en: "About",
          zh: "关于"
        },
        language: {
          en: "Language",
          zh: "语言"
        }
      }
    };
  },
  computed: {
    routeName() {
      return this.$route.name;
    }
  },
  watch: {
    menuSwitch(val) {
      this.$emit("menuSwitch", val);
    }
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.hideMenu();
    }, false);
  },
  methods: {
    ...mapActions(["changeProductsBrand", "changeCategoriesChoose"]),
    hideMenu() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if (scrollTop != 0) {
        this.hideMenus = true;
      } else {
        this.hideMenus = false;
      }
    },
    toRouter(data, params) {
      this.menuSwitch = false;
      setTimeout(() => {
        if (data == "language") {
          let language = window.localStorage.getItem("language");
          if (language != params) {
            window.localStorage.setItem("language", params);
            location.reload();
          }
          return;
        } else if (data == "products") {
          this.changeProductsBrand(params);
          this.changeCategoriesChoose("color_pencil");
          this.$router.push({
            name: data,
            params: {
              brand: params
            }
          });
        } else {
          this.$router.push({
            name: data
          });
        }
      }, 700);
    }
  }
};