import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue";
import _imports_0 from '@/assets/common/nyoni_white.png';
import _imports_1 from '@/assets/common/nyoni_black.png';
import _imports_2 from '../../assets/icon/arrow-down-white.svg';
import _imports_3 from '../../assets/icon/arrow-down-black.svg';
const _hoisted_1 = {
  href: "/"
};
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: "logo",
  class: "logo"
};
const _hoisted_3 = {
  key: 1,
  src: _imports_1,
  alt: "logo",
  class: "logo"
};
const _hoisted_4 = {
  class: "header-bar-list"
};
const _hoisted_5 = {
  href: "/"
};
const _hoisted_6 = {
  href: "javaScript:void(0)"
};
const _hoisted_7 = {
  key: 0,
  class: "hasArraw",
  src: _imports_2,
  alt: ""
};
const _hoisted_8 = {
  key: 1,
  class: "hasArraw",
  src: _imports_3,
  alt: ""
};
const _hoisted_9 = {
  href: "/contact"
};
const _hoisted_10 = {
  href: "javaScript:void(0)"
};
const _hoisted_11 = {
  key: 0,
  class: "hasArraw",
  src: _imports_2,
  alt: ""
};
const _hoisted_12 = {
  key: 1,
  class: "hasArraw",
  src: _imports_3,
  alt: ""
};
const _hoisted_13 = {
  href: "javaScript:void(0)"
};
const _hoisted_14 = {
  href: "javaScript:void(0)"
};
const _hoisted_15 = {
  href: "/shop"
};
const _hoisted_16 = {
  href: "javaScript:void(0)"
};
const _hoisted_17 = {
  key: 0,
  class: "hasArraw",
  src: _imports_2,
  alt: ""
};
const _hoisted_18 = {
  key: 1,
  class: "hasArraw",
  src: _imports_3,
  alt: ""
};
const _hoisted_19 = {
  href: "javaScript:void(0)"
};
const _hoisted_20 = {
  href: "javaScript:void(0)"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    class: _normalizeClass(["header-bar", {
      dark_color: $props.isDarkColor,
      hideBar: $data.hideMenus
    }])
  }, [_createElementVNode("a", _hoisted_1, [!$props.isDarkColor ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : (_openBlock(), _createElementBlock("img", _hoisted_3))]), _createElementVNode("ul", _hoisted_4, [_createElementVNode("li", null, [_createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.$t("common.header.home")), 1)]), _createElementVNode("li", {
    class: _normalizeClass(["relative hasSubMenu", {
      active: $data.showProducts == true
    }]),
    onClick: _cache[2] || (_cache[2] = $event => $options.showProduct()),
    onMouseenter: _cache[3] || (_cache[3] = $event => $options.showProduct(true)),
    onMouseleave: _cache[4] || (_cache[4] = $event => $options.showProduct(false))
  }, [_createElementVNode("a", _hoisted_6, [_createTextVNode(_toDisplayString(_ctx.$t("common.header.products.products")) + " ", 1), !$props.isDarkColor ? (_openBlock(), _createElementBlock("img", _hoisted_7)) : (_openBlock(), _createElementBlock("img", _hoisted_8))]), _createElementVNode("ul", {
    class: _normalizeClass(["subMenu", {
      active: $data.showProducts == true
    }])
  }, [_createElementVNode("li", null, [_createElementVNode("a", {
    href: "javaScript:void(0)",
    onClick: _cache[0] || (_cache[0] = $event => $options.toProduct('nyoni'))
  }, _toDisplayString(_ctx.$t("common.header.products.nyoni")), 1)]), _createElementVNode("li", null, [_createElementVNode("a", {
    href: "javaScript:void(0)",
    onClick: _cache[1] || (_cache[1] = $event => $options.toProduct('feiyan'))
  }, _toDisplayString(_ctx.$t("common.header.products.feiyan")), 1)])], 2)], 34), _createElementVNode("li", null, [_createElementVNode("a", _hoisted_9, _toDisplayString(_ctx.$t("common.header.contact")), 1)]), _createElementVNode("li", {
    class: _normalizeClass(["relative hasSubMenu", {
      active: $data.showAbouts == true
    }]),
    onClick: _cache[7] || (_cache[7] = $event => $options.showAbout()),
    onMouseenter: _cache[8] || (_cache[8] = $event => $options.showAbout(true)),
    onMouseleave: _cache[9] || (_cache[9] = $event => $options.showAbout(false))
  }, [_createElementVNode("a", _hoisted_10, [_createTextVNode(_toDisplayString(_ctx.$t("common.header.about.about")) + " ", 1), !$props.isDarkColor ? (_openBlock(), _createElementBlock("img", _hoisted_11)) : (_openBlock(), _createElementBlock("img", _hoisted_12))]), _createElementVNode("ul", {
    class: _normalizeClass(["subMenu", {
      active: $data.showAbouts == true
    }])
  }, [_createElementVNode("li", {
    onClick: _cache[5] || (_cache[5] = $event => $options.toAbout('nyoni'))
  }, [_createElementVNode("a", _hoisted_13, _toDisplayString(_ctx.$t("common.header.about.nyoni")), 1)]), _createElementVNode("li", {
    onClick: _cache[6] || (_cache[6] = $event => $options.toAbout('jiuling'))
  }, [_createElementVNode("a", _hoisted_14, _toDisplayString(_ctx.$t("common.header.about.jiuling")), 1)])], 2)], 34), _createElementVNode("li", null, [_createElementVNode("a", _hoisted_15, _toDisplayString(_ctx.$t("common.header.buy")), 1)]), _createElementVNode("li", {
    class: _normalizeClass(["relative hasSubMenu", {
      active: $data.showLanguages == true
    }]),
    onClick: _cache[12] || (_cache[12] = $event => $options.showLanguage()),
    onMouseenter: _cache[13] || (_cache[13] = $event => $options.showLanguage(true)),
    onMouseleave: _cache[14] || (_cache[14] = $event => $options.showLanguage(false))
  }, [_createElementVNode("a", _hoisted_16, [_createTextVNode(_toDisplayString(_ctx.$t("common.header.language.lg")) + " ", 1), !$props.isDarkColor ? (_openBlock(), _createElementBlock("img", _hoisted_17)) : (_openBlock(), _createElementBlock("img", _hoisted_18))]), _createElementVNode("ul", {
    class: _normalizeClass(["subMenu", {
      active: $data.showLanguages == true
    }])
  }, [_createElementVNode("li", {
    onClick: _cache[10] || (_cache[10] = $event => $options.changeLanguage('zh'))
  }, [_createElementVNode("a", _hoisted_19, _toDisplayString(_ctx.$t("common.header.language.zh")), 1)]), _createElementVNode("li", {
    onClick: _cache[11] || (_cache[11] = $event => $options.changeLanguage('en'))
  }, [_createElementVNode("a", _hoisted_20, _toDisplayString(_ctx.$t("common.header.language.en")), 1)])], 2)], 34)])], 2)]);
}