import "core-js/modules/es.array.push.js";
import ProductList from "@/components/Common/ProductList.vue";
import nyoniProductsLists from "@/modules/nyoniProductsLists";
import feiyanProductsLists from "@/modules/feiyanProductsLists";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    ProductList
  },
  computed: {
    ...mapGetters({
      categoriesChoose: "getCategoriesChoose",
      productsBrand: "getProductsBrand"
    })
  },
  data() {
    return {
      productBrand: "",
      productsList: [],
      isLoading: false,
      opacityHide: true,
      nyoniProductsLists: nyoniProductsLists,
      feiyanProductsLists: feiyanProductsLists
    };
  },
  watch: {
    productsBrand(val) {
      this.productBrand = val;
      this.changeProductsList(val);
    }
  },
  created() {
    this.changeProductsList();
  },
  methods: {
    ...mapActions(["changeCategoriesChoose", "changeProductsBrand"]),
    switchCategories(data) {
      this.changeCategoriesChoose(data);
      this.changeProductsList();
    },
    // 更换列表数据
    changeProductsList(data) {
      if (!data) {
        this.productBrand = this.$route.params.brand;
        this.changeProductsBrand(this.$route.params.brand);
      }
      if (this.productBrand == "nyoni") {
        this.productsList = this.nyoniProductsLists[this.categoriesChoose];
      }
      if (this.productBrand == "feiyan") {
        this.productsList = this.feiyanProductsLists[this.categoriesChoose];
      }
    },
    // 更换产品品牌
    switchProductsBrand(data) {
      this.$router.push({
        name: "products",
        params: {
          brand: data
        }
      });
      this.changeProductsBrand(data);
      this.changeCategoriesChoose("color_pencil");
    }
  }
};