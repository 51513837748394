import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "header-fill"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Menunav = _resolveComponent("Menunav");
  const _component_MenuDrawer = _resolveComponent("MenuDrawer");
  const _component_router_view = _resolveComponent("router-view");
  const _component_Footernav = _resolveComponent("Footernav");
  const _component_el_backtop = _resolveComponent("el-backtop");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["wrapper", {
      'menu-drawer-open': $data.menuDrawerOpen
    }])
  }, [_createVNode(_component_Menunav, {
    isDarkColor: !$options.isHome
  }, null, 8, ["isDarkColor"]), _createVNode(_component_MenuDrawer, {
    onMenuSwitch: $options.changeMenuSwitch
  }, null, 8, ["onMenuSwitch"]), !$options.isHome ? (_openBlock(), _createElementBlock("div", _hoisted_1)) : _createCommentVNode("", true), (_openBlock(), _createBlock(_component_router_view, {
    key: $options.key
  })), _createVNode(_component_Footernav), _createVNode(_component_el_backtop, {
    right: 56,
    bottom: 56,
    style: {
      "color": "#000"
    }
  })], 2);
}