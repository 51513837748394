import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-42830e53"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "buy-container"
};
const _hoisted_2 = {
  class: "pb-40 pl-20"
};
const _hoisted_3 = {
  class: "detail-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BuyList = _resolveComponent("BuyList");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("buy.title")), 1)]), _createVNode(_component_BuyList, {
    buyList: $data.buyList
  }, null, 8, ["buyList"])]);
}