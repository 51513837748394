import { createRouter, createWebHistory } from "vue-router";
import home from "../views/Home.vue";
import nyoni from "../views/About/Nyoni.vue";
import jiuling from "../views/About/Jiuling.vue";
import contact from "../views/Contact.vue";
import shop from "../views/Shop/Shop.vue";
import products from "../views/Products/Products.vue";
// import details from "../views/Products/ProductsDetails.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: home,
  },
  {
    path: "/about/nyoni",
    name: "nyoni",
    component: nyoni,
  },
  {
    path: "/about/jiuling",
    name: "jiuling",
    component: jiuling,
  },
  {
    path: "/contact",
    name: "contact",
    component: contact,
  },
  {
    path: "/shop",
    name: "shop",
    component: shop,
  },
  {
    path: "/products/:brand",
    name: "products",
    component: products,
  },
  // 产品详情页 为了方便seo 暂时删除 意义不大
  // {
  //   path: "/products/:brand/:model",
  //   name: "details",
  //   component: details,
  // },
  // 重定向 - home
  {
    path: "/:pathMatch(.*)",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});
export default router;
