import { ElCarousel, ElCarouselItem } from "element-plus";
export default {
  components: {
    ElCarousel,
    ElCarouselItem
  },
  data() {
    return {
      bannerHeight: "",
      size: "",
      isEnOrZh: window.localStorage.getItem("language"),
      autoplay: true
    };
  },
  created() {
    this.changeImageSize();
  },
  mounted() {
    this.imgLoad();
    this.slideBanner();
    window.addEventListener("resize", () => {
      this.changeImageSize();
      this.imgLoad();
    }, false);
  },
  methods: {
    imgLoad() {
      this.$nextTick(() => {
        this.bannerHeight = this.$refs.bannerHeight[0].height;
        console.log("height", this.$refs.bannerHeight[0]);
      });
    },
    changeImageSize() {
      let width = document.body.offsetWidth;
      if (width > 576) {
        this.size = "large";
      } else {
        this.size = "small";
      }
    },
    slideBanner() {
      //选中item的盒子
      let box = document.getElementById("carouselBanner");
      //手指起点X坐标
      let startPoint = 0;
      //手指滑动重点X坐标
      let stopPoint = 0;
      //手指起点X坐标
      let startPointY = 0;
      //手指滑动重点X坐标
      let stopPointY = 0;

      //重置坐标
      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
        startPointY = 0;
        stopPointY = 0;
      };
      //手指按下
      box.addEventListener("touchstart", e => {
        //手指按下的时候停止自动轮播
        // this.stopAuto();
        this.autoplay = false;
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
        startPointY = e.changedTouches[0].pageY;
      });
      //手指滑动
      box.addEventListener("touchmove", e => {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
        stopPointY = e.changedTouches[0].pageY;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      box.addEventListener("touchend", () => {
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          this.autoplay = true;
          return;
        }
        if (Math.abs(stopPointY - startPointY) > 30) {
          resetPoint();
          this.autoplay = true;
          return;
        }
        if (startPoint - stopPoint > 0) {
          resetPoint();
          this.autoplay = true;
          this.$refs.carouselBanner.next();
          return;
        }
        if (startPoint - stopPoint < 0) {
          resetPoint();
          this.autoplay = true;
          this.$refs.carouselBanner.prev();
          return;
        }
      });
    }
  }
};