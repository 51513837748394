import "core-js/modules/es.array.push.js";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      routerName: this.$route.name
    };
  },
  methods: {
    ...mapActions(["changeCategoriesChoose", "changeProductsBrand"]),
    toProducts(data) {
      this.changeCategoriesChoose(data);
      if (data == "school_office_pencil") {
        this.changeProductsBrand("feiyan");
        this.$router.push({
          name: "products",
          params: {
            brand: "feiyan"
          }
        });
      } else {
        this.changeProductsBrand("nyoni");
        this.$router.push({
          name: "products",
          params: {
            brand: "nyoni"
          }
        });
      }
    }
  }
};